import { StyleSheet } from "react-native";

export default StyleSheet.create({
  viewDetail: {
    width: "95%",
    marginRight: 25,
    alignSelf: "flex-end",
    backgroundColor: "#FFFFFF",
    marginTop: -50,
    borderRadius: 4,
    padding: 40,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  textTitle: {
    fontSize: 40,
    color: "#161A31",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  viewNumeral: {
    width: "45%",
    height: 233,
    borderWidth: 3,
    borderColor: "#016481",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  marginHorizontal:10
  },
  textNumeral: {
    fontSize: 40,
    fontFamily: "Roboto",
    color: "#8a8a8a",
    fontWeight: "bold",
  },
  textNumber: {
    fontSize: 96,
    color: "#161A31",
    fontFamily: "Roboto",
  },
  textSublject: {
    fontSize: 20,
    fontFamily: "Roboto",
    color: "#FFFFFF",
    textAlign: "center",
  },
  viewSubject: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#161A31",
    borderRadius: 5,
    height: 59,
    paddingHorizontal: 45,
    alignItems: "center",
  },
  textDetail: {
    fontSize: 20,
    fontFamily: "Roboto",
    color: "#535659",
    textAlign: "center",
  },
  viewDetailAccess: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#e8e9eb",
    paddingVertical: 22,
    paddingHorizontal: 45,
    marginTop: 17,
  },
  buttonSeeMore: {
    width: "100%",
    height: 30,
    backgroundColor: "#161A31",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    alignSelf: "center",
    maxWidth: 91,
  },
  text16W: {
    fontSize: 16,
    fontFamily: "Roboto",
    color: "#FFFFFF",
  },
  text: {
    fontSize: 30,
    color: "#161A31",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  imgGlass: {
    width: 22,
    height: 22,
    position: "absolute",
    zIndex: 99,
    marginTop: 11,
    marginLeft: 10,
  },
  inputSeach: {
    width: "100%",
    height: 44,
    backgroundColor: "#E7E7E7",
    borderRadius: 5,
    paddingLeft: 40,
    paddingRight: 10,
    fontSize: 16,
    color: "#a2a2a2",
    fontFamily: "Roboto",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  buttonModal: {
    width: 100,
    height: 38,
    backgroundColor: "#161A31",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    alignSelf: "flex-end",
  },
  viewModal: {
    width: "80%",
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    padding: 40,
    alignSelf: "center",
  },
});
