import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "recoil-persist",
  storage: localStorage,
});

export const tokenState = atom({
  key: "token",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
