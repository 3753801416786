import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  ScrollView,
  Dimensions,
} from "react-native";
import Chart from "react-google-charts";
import { AntDesign } from "@expo/vector-icons";
import Menu from "../../component/menu";
import { useWindowSize } from "../../component/responsive";
import AnimatedNumber from "animated-number-react";
import HeaderTitle from "../../component/headerTitle";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import styles from "./Style";
import { tokenState } from "../../reducer/Atom";
import { useRecoilState } from "recoil";
const { width, height } = Dimensions.get("window");
import { apiservice } from "../../apiservice/api";
import moment from "moment";

export default function Home({ navigation, route }) {
  const [modalDetail, setModalDetail] = useState(false);
  const [infoModal, setInfoModal] = useState({});
  const [media_type, smedia_type] = useState(null);
  const size = useWindowSize();
  let year = new Date().getFullYear();

  const placeholder = {
    label: year,
    value: year,
    color: "#8B8D98",
  };
  const placeholder1 = {
    label: "All",
    value: null,
  };
  const [selectYear, setSelectYear] = useState([
    { label: year - 1, value: year - 1 },
    { label: year - 2, value: year - 2 },
    { label: year - 3, value: year - 3 },
    { label: year - 4, value: year - 4 },
  ]);

  const [selectType, setSelectType] = useState([
    { label: "book", value: "book" },
    { label: "card", value: "card" },
  ]);
  const [token, settoken] = useRecoilState(tokenState);
  const [state, setstate] = useState("");
  const [month, setmonth] = useState(null);
  const [media, setmedia] = useState({});
  const [Contents, seContents] = useState({});
  const [user, setuser] = useState([]);
  const [accessHis, setaccessHis] = useState([]);
  const [accessHisInfo, setaccessHisInfo] = useState([]);

  // console.log(infoModal);
  const [paginations, setPaginations] = useState([]);
  const [page, setpage] = useState(1);
  const [total_page, setTotalpage] = useState(0);
  const [history, sethistory] = useState({});

  async function dashboard(params) {
    const response = await apiservice({
      method: "get",
      path:
        "api/admin/dashboard/access_history?page=" +
        1 +
        "&limit=1000&search=&type=",
      token: token.access_token,
    });

    const response1 = await apiservice({
      method: "get",
      path:
        "api/admin/dashboard/access_history?page=" +
        params +
        "&limit=10&search=&type=",
      token: token.access_token,
    });

    if (response.status == 200) {
      // console.log("dashboard", response.data);
      // setaccessHis(response.data.result.access_history);
      // setstate(response.data.result);
      let pagination = [];
      for (
        let index = 0;
        index < response.data.result.access_history?.length / 10;
        index++
      ) {
        pagination = pagination.concat({});
      }

      console.log("dashboard", response.data);

      setTotalpage(pagination.length);
      setPaginations(pagination);
      console.log("pagination", pagination);
      setaccessHis(response1.data.result.access_history);
    } else {
    }
  }

  async function total() {
    const response = await apiservice({
      method: "get",
      path: "api/admin/dashboard/total",
      token: token.access_token,
    });
    if (response.status == 200) {
      // console.log("AAA", response.data);
      setstate(response.data.result);
    }
  }

  async function chart() {
    const response = await apiservice({
      method: "get",
      path: "api/admin/dashboard/user_chart",
      token: token.access_token,
    });
    if (response.status == 200) {
      setmonth(response.data.result.user_count_chart[year] ?? moment().get('mm'));
    }
  }

  async function chartselect(params) {
    const response = await apiservice({
      method: "get",
      path: "api/admin/dashboard/user_chart",
      token: token.access_token,
    });
    if (response.status == 200) {
      setmonth(response?.data?.result?.user_count_chart[params] ?? { Jan: 0 });
    }
  }

  async function name(params, yeer) {
    //api.connectvar.com/api/admin/dashboard/access_history/:media_uid?year=2022

    const res = await apiservice({
      method: "get",
      path: "api/admin/dashboard/access_history/" + params + "?year=" + yeer,
      token: token.access_token,
    });

    if (res.status == 200) {
      setaccessHisInfo(res.data.result.access_history_user_list ?? []);
    }
  }

  const [itema, setitem] = useState("");

  async function mediatypeall() {
    const response = await apiservice({
      method: "get",
      path: "api/admin_media_types",
      token: token.access_token,
    });

    if (response.status == 200) {
      setitem(
        response.data.result.media.map((item) => ({
          label: item.type_name,
          value: item.type_slug,
        }))
      );
      setSelectType(
        response.data.result.media.map((item) => ({
          label: item.type_name,
          value: item.type_slug,
        }))
      );
    }
  }

  async function userall() {
    const response = await apiservice({
      method: "get",
      path: "api/admin_users?page=1&limit=100&search=",
      token: token.access_token,
    });
    // console.log("user", response.data.result.users?.length);
    if (response.status == 200) {
      const data = response.data.result.users?.filter((item) => {
        return item.delete_flag == false;
      }) ?? [];
      // console.log("data", data);
      setuser(data?.length);
      // setstate(response.data.result.users);
    }
  }

  async function mediaall() {
    const response = await apiservice({
      method: "get",
      path: "api/admin_media/media?page=1&limit=100&search=",
      token: token.access_token,
    });
    console.log("DATA", response.data.result.media);
    if (response.status == 200) {
      const data = response.data.result.media?.filter((item) => {
        return item.delete_flag == false;
      }) ?? [];
      console.log("data", data);
      setmedia(data?.length);
    }
  }

  useEffect(() => {
    mediaall();
    total();
    chart();
    dashboard(1);
    mediatypeall();
    userall();
  }, [token]);

  if (month == null) {
    return <View />;
  }

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <ScrollView>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Menu home navigation={navigation} />

          <View style={{ width: "80%", justifyContent: "space-between" }}>
            <HeaderTitle title="Home" navigation={navigation} />
            <View style={styles.viewDetail}>
              <Text style={styles.textTitle}>Dashboard</Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("User");
                  }}
                  style={styles.viewNumeral}
                >
                  <Text style={styles.textNumeral}>User</Text>
                  <Text
                    style={[
                      styles.textNumber,
                      { fontSize: (size.width * 5) / 100 },
                    ]}
                  >
                    <AnimatedNumber
                      value={user}
                      formatValue={(value) =>
                        value
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                    />
                  </Text>
                </TouchableOpacity>
                <View style={styles.viewNumeral}>
                  <Text style={styles.textNumeral}>Media</Text>
                  <Text
                    style={[
                      styles.textNumber,
                      { fontSize: (size.width * 5) / 100 },
                    ]}
                  >
                    <AnimatedNumber
                      value={media}
                      formatValue={(value) =>
                        value
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                    />
                  </Text>
                </View>
                {/* <View style={styles.viewNumeral}>
                <Text style={styles.textNumeral}>Contents</Text>
                <Text
                  style={[
                    styles.textNumber,
                    { fontSize: (size.width * 5) / 100 },
                  ]}
                >
                  <AnimatedNumber
                    value={state.total_content_count}
                    formatValue={(value) =>
                      value
                        .toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                </Text>
              </View> */}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 40,
                }}
              >
                <Text style={styles.text}>
                  User:{" "}
                  <Text style={{ color: "#016481" }}>
                    {user}
                    {/* {"15000".toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    " Account"} */}
                  </Text>
                </Text>
                <RNPickerSelect
                  placeholder={placeholder}
                  onValueChange={(val) => {
                    chartselect(val);
                  }}
                  items={selectYear}
                  style={{
                    inputWeb: {
                      backgroundColor: "#FFFFFF",
                      borderColor: "#8B8D98",
                      width: 165,
                      height: 40,
                      color: "#8B8D98",
                      fontFamily: "Prompt",
                      fontSize: 16,
                      paddingHorizontal: 10,
                      borderRadius: 5,
                    },
                  }}
                />
              </View>
              <Chart
                width={"100%"}
                height={"400px"}
                chartType="ColumnChart"
                loader={<Text>Loading Chart</Text>}
                data={[
                  ["month", "Account"],
                  ["Jan.", month.Jan],
                  ["Feb.", month.Feb],
                  ["Apr.", month.Apr],
                  ["May", month.May],
                  ["Jun.", month.Jun],
                  ["Jul.", month.Jul],
                  ["Aug.", month.Aug],
                  ["Sep.", month.Sep],
                  ["Oct.", month.Oct],
                  ["Nov.", month.Nov],
                  ["Dec.", month.Dec],
                ]}
                options={{
                  hAxis: { title: "month", minValue: 0, maxValue: 15 },
                  //   vAxis: { title: "", minValue: 0, maxValue: 15 },
                  legend: "none",
                  animation: {
                    startup: true,
                    easing: "linear",
                    duration: 1500,
                  },

                  enableInteractivity: false,
                  colors: ["#505365", "#505365"],
                }}
                chartEvents={[
                  {
                    eventName: "animationfinish",
                    callback: () => {
                      console.log("Animation Finished");
                    },
                  },
                ]}
                rootProps={{ "data-testid": "2" }}
              />
              <Text style={styles.textTitle}>Access history</Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginVertical: 21,
                }}
              >
                <View style={{ width: "20%" }}>
                  <Image
                    style={styles.imgGlass}
                    source={require("../../img/glass-icon.png")}
                  />
                  <TextInput
                    placeholder="ค้นหามีเดีย"
                    style={styles.inputSeach}
                  />
                </View>
                <RNPickerSelect
                  placeholder={{ label: "All", value: null }}
                  value={media_type}
                  onValueChange={(val) => {
                    smedia_type(val);
                  }}
                  items={selectType}
                  style={{
                    inputWeb: {
                      backgroundColor: "#FFFFFF",
                      borderColor: "#8B8D98",
                      width: 165,
                      height: 58,
                      color: "#8B8D98",
                      fontFamily: "Prompt",
                      fontSize: 16,
                      paddingHorizontal: 10,
                      borderRadius: 5,
                    },
                  }}
                />
              </View>
              <View style={styles.viewSubject}>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "5%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  No.
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Type
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "35%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  Media Name
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Cover
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "15%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  status
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Viewed
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "15%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Statistics
                </Text>
              </View>
              <FlatList
                data={accessHis?.filter((e) => {
                  if (media_type) {
                    return e.media_type == media_type;
                  } else {
                    return true;
                  }
                })}
                extraData={accessHis}
                numColumns={1}
                renderItem={({ index, item }) => {
                  return (
                    <View style={styles.viewDetailAccess}>
                      <Text
                        style={[
                          styles.textDetail,
                          {
                            width: "5%",
                            textAlign: "left",
                            fontSize: (size.width * 1) / 100,
                          },
                        ]}
                      >
                        {index + 1}
                      </Text>
                      <Text
                        style={[
                          styles.textDetail,
                          { width: "10%", fontSize: (size.width * 1) / 100 },
                        ]}
                      >
                        {item.media_type}
                      </Text>
                      <Text
                        style={[
                          styles.textDetail,
                          {
                            width: "35%",
                            textAlign: "left",
                            fontSize: (size.width * 1) / 100,
                          },
                        ]}
                      >
                        {item.media_name}
                      </Text>
                      <View style={{ width: "10%", alignItems: "center" }}>
                        <Image
                          style={{ width: 114, height: 143 }}
                          source={{ uri: item.media_cover_url }}
                        />
                      </View>
                      <Text
                        style={[
                          styles.textDetail,
                          {
                            width: "15%",
                            color: "#26BB2C",
                            fontSize: (size.width * 1) / 100,
                          },
                        ]}
                      >
                        {item.delete_flag ? "FALSE" : "TRUE"}
                      </Text>
                      <Text
                        style={[
                          styles.textDetail,
                          { width: "10%", fontSize: (size.width * 1) / 100 },
                        ]}
                      >
                        {item.view_count}
                      </Text>
                      <View style={{ width: "15%" }}>
                        <TouchableOpacity
                          onPress={() => {
                            setModalDetail(true);
                            setInfoModal(item);
                            name(item.media_uid, year);
                          }}
                          style={styles.buttonSeeMore}
                        >
                          <Text
                            style={[
                              styles.text16W,
                              {
                                fontSize: (size.width * 1) / 100,
                              },
                            ]}
                          >
                            See more
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  );
                }}
              />

              <View
                style={{
                  width: width * 0.689,
                  alignItems: "flex-end",
                  height: 100,
                }}
              >
                <View
                  style={{
                    marginVertical: 10,
                    width: "100%",
                    alignItems: "flex-end",
                  }}
                >
                  <FlatList
                    data={paginations?.filter((item, index) => {
                      return page == total_page
                        ? true
                        : page <= index && page + 3 >= index;
                    })}
                    horizontal
                    renderItem={({ item, index }) => {
                      return (
                        <View style={{ flexDirection: "row" }}>
                          {page != 1 && index == 0 && (
                            <>
                              <TouchableOpacity
                                onPress={() => {
                                  dashboard(page - 1);
                                  setpage(page - 1);
                                }}
                                style={{
                                  width: width * 0.026,
                                  height: height * 0.046,
                                  backgroundColor: "#ffff",
                                  justifyContent: "center",
                                  zIndex: 99,
                                }}
                              >
                                <AntDesign
                                  name="caretleft"
                                  size={18}
                                  color="black"
                                />
                              </TouchableOpacity>
                              <TouchableOpacity
                                onPress={() => {
                                  dashboard(1);
                                  setpage(1);
                                }}
                                style={{
                                  width: width * 0.026,
                                  height: height * 0.046,
                                  backgroundColor: "#ffff",
                                  justifyContent: "center",
                                  zIndex: 99,
                                }}
                              >
                                <Text
                                  style={[
                                    styles.fontDataTable,
                                    { textAlign: "center" },
                                  ]}
                                >
                                  {1}
                                </Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                disabled
                                style={{
                                  width: width * 0.026,
                                  height: height * 0.046,
                                  backgroundColor: "#ffff",
                                  justifyContent: "center",
                                  zIndex: 99,
                                }}
                              >
                                <Text
                                  style={[
                                    styles.fontDataTable,
                                    { textAlign: "center" },
                                  ]}
                                >
                                  ...
                                </Text>
                              </TouchableOpacity>
                            </>
                          )}
                          {page != total_page && (
                            <TouchableOpacity
                              onPress={() => {
                                dashboard(page + index);
                                setpage(page + index);
                              }}
                              style={{
                                width: width * 0.03,
                                height: height * 0.04,
                                backgroundColor:
                                  page + index == page ? "#3D6B99" : "#ffff",
                                justifyContent: "center",
                                borderRadius: 140,
                                zIndex: 99,
                              }}
                            >
                              <Text
                                style={[
                                  styles.fontDataTable,
                                  {
                                    textAlign: "center",
                                    color:
                                      page + index == page ? "#fff" : "#000",
                                  },
                                ]}
                              >
                                {page + index}
                              </Text>
                            </TouchableOpacity>
                          )}
                          {page != total_page &&
                            index ==
                            paginations?.filter(
                              (item, index) =>
                                page <= index && page + 3 >= index
                            ).length -
                            1 && (
                              <>
                                <TouchableOpacity
                                  disabled
                                  style={{
                                    width: width * 0.026,
                                    height: height * 0.046,
                                    backgroundColor: "#ffff",
                                    justifyContent: "center",
                                    zIndex: 99,
                                  }}
                                >
                                  <Text
                                    style={[
                                      styles.fontDataTable,
                                      { textAlign: "center" },
                                    ]}
                                  >
                                    ...
                                  </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  onPress={() => {
                                    dashboard(total_page);
                                    setpage(total_page);
                                  }}
                                  style={{
                                    width: width * 0.026,
                                    height: height * 0.046,
                                    backgroundColor: "#ffff",
                                    justifyContent: "center",
                                    zIndex: 99,
                                  }}
                                >
                                  <Text
                                    style={[
                                      styles.fontDataTable,
                                      { textAlign: "center" },
                                    ]}
                                  >
                                    {total_page}
                                  </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  onPress={() => {
                                    dashboard(page + 1);
                                    setpage(page + 1);
                                  }}
                                  style={{
                                    width: width * 0.026,
                                    height: height * 0.046,
                                    backgroundColor: "#ffff",
                                    justifyContent: "center",
                                    zIndex: 99,
                                  }}
                                >
                                  <AntDesign
                                    name="caretright"
                                    size={18}
                                    color="black"
                                  />
                                </TouchableOpacity>
                              </>
                            )}
                        </View>
                      );
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
        <Modal
          transparent={true}
          visible={modalDetail}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalDetail(!modalDetail);
          }}
        >
          <View style={styles.bgModal}>
            <View style={styles.viewModal}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 36,
                    fontFamily: "Roboto",
                    color: "#161A31",
                  }}
                >
                  Media Name:{" "}
                  <Text style={{ color: "#a2a2a2" }}>
                    {infoModal.media_name}
                  </Text>
                </Text>
                <RNPickerSelect
                  placeholder={placeholder}
                  onValueChange={(val) => {
                    name(infoModal.media_uid, val);
                  }}
                  items={selectYear}
                  style={{
                    inputWeb: {
                      backgroundColor: "#FFFFFF",
                      borderColor: "#8B8D98",
                      width: 165,
                      height: 40,
                      color: "#8B8D98",
                      fontFamily: "Prompt",
                      fontSize: 16,
                      paddingHorizontal: 10,
                    },
                  }}
                />
              </View>
              <View style={styles.viewSubject}>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "5%", textAlign: "left" },
                  ]}
                >
                  No.
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "30%", textAlign: "left" },
                  ]}
                >
                  E-mail
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "20%", textAlign: "left" },
                  ]}
                >
                  Phone
                </Text>
                <Text style={[styles.textSublject, { width: "15%" }]}>
                  Date
                </Text>
                <Text style={[styles.textSublject, { width: "15%" }]}>
                  time
                </Text>
                <Text style={[styles.textSublject, { width: "10%" }]}>
                  status
                </Text>
              </View>
              <FlatList
                data={accessHisInfo}
                numColumns={1}
                renderItem={({ index, item }) => {
                  console.log("asd", item.media_type);
                  return (
                    <View style={styles.viewDetailAccess}>
                      <Text
                        style={[
                          styles.textDetail,
                          { width: "5%", textAlign: "left" },
                        ]}
                      >
                        {index + 1}
                      </Text>
                      <Text
                        style={[
                          styles.textDetail,
                          { width: "30%", textAlign: "left" },
                        ]}
                      >
                        {item.User.email}
                      </Text>
                      <Text
                        style={[
                          styles.textDetail,
                          { width: "20%", textAlign: "left" },
                        ]}
                      >
                        {item.User.phone}
                      </Text>
                      <Text style={[styles.textDetail, { width: "15%" }]}>
                        {item.date}
                      </Text>
                      <Text style={[styles.textDetail, { width: "15%" }]}>
                        {item.time} น.
                      </Text>
                      <Text
                        style={[
                          styles.textDetail,
                          { width: "10%", color: "#26BB2C" },
                        ]}
                      >
                        {item.status}
                      </Text>
                    </View>
                  );
                }}
              />
              <TouchableOpacity
                onPress={() => {
                  setModalDetail(!modalDetail);
                }}
                style={styles.buttonModal}
              >
                <Text style={styles.text16W}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </View>
  );
}
