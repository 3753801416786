import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TextInput,
  TouchableOpacity,
  Button,
} from "react-native";
import { useWindowSize } from "./responsive";
import TitleButton from "./titleButton";
const { width, height } = Dimensions.get("window");
export default function menu({
  title,
  home,
  book,
  user,
  manage,
  Admin,
  navigation,
}) {
  const size = useWindowSize();
  const [isBooped, setIsBooped] = useState(false);
  return (
    <View
      style={{
        width: "20%",
        minWidth: 250,
        minHeight: size.height,
        backgroundColor: "#FFFFFF",
        shadowColor: "#707070",
        shadowOffset: { width: 2, height: 2 },
        shadowRadius: 10,
      }}
    >
      <View
        style={{
          backgroundColor: "#e3e3e3",
          paddingVertical: 15,
          height: 210,
          justifyContent: "center",
        }}
      >
        <Image
          resizeMode={"contain"}
          style={{ width: 119, height: 121, alignSelf: "center" }}
          source={require("../img/logo.png")}
        />
      </View>
      <TitleButton
        title={"Home"}
        select={home}
        onPress={() => {
          navigation.navigate("Home");
        }}
      />
      <TitleButton
        title={"Media information"}
        select={book}
        onPress={() => {
          navigation.navigate("Book");
        }}
      />
      <TitleButton
        title={"User information"}
        select={user}
        onPress={() => {
          navigation.navigate("User");
        }}
      />
      <TitleButton
        title={"Manage media type"}
        select={manage}
        onPress={() => {
          navigation.navigate("Manage");
        }}
      />

      <TitleButton
        title={"Admin information"}
        select={Admin}
        onPress={() => {
          navigation.navigate("Admin");
        }}
      />
    </View>
  );
}
