import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  FlatList,
  ScrollView,
} from "react-native";
import Menu from "../../component/menu";
import HeaderTitle from "../../component/headerTitle";
import { useWindowSize } from "../../component/responsive";
import Modal from "modal-react-native-web";
import styles from "./Style";
import { apiservice } from "../../apiservice/api";
import { tokenState } from "../../reducer/Atom";
import { useRecoilState } from "recoil";
export default function User({ navigation }) {
  const [modalDelete, setModalDelete] = useState(false);
  const [modalUser, setModalUser] = useState(false);
  const [modalUserED, setModalUserED] = useState(false);
  const size = useWindowSize();
  const [state, setstate] = useState([]);
  const [data, setdata] = useState("");
  const [Email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [token, settoken] = useRecoilState(tokenState);
  const [search, setsearch] = useState("");

  async function userall() {
    const response = await apiservice({
      method: "get",
      path: "api/admin_users?page=1&limit=100&search=",
      token: token.access_token,
    });
    console.log("user", response.data);
    if (response.status == 200) {
      setstate(response.data.result.users);
    }
  }
  useEffect(() => {
    userall();
  }, [token]);

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <ScrollView>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Menu user navigation={navigation} />
        <View style={{ width: "80%" }}>
          <HeaderTitle title="User information" navigation={navigation} />
          <View style={[styles.viewDetail, { minHeight: "80%" }]}>
            <View style={{ width: "100%" }}>
              <Image
                style={styles.imgGlass}
                source={require("../../img/glass-icon.png")}
              />
              <TextInput
                onChangeText={setsearch}
                onSubmitEditing={async () => {
                  const res = await apiservice({
                    path: "api/admin_users?page=1&limit=1000&search=" + search,
                    method: "get",
                    token: token.access_token,
                  });
                  if (res.status == 200) {
                    setstate(res.data.result.users);
                  }
                }}
                placeholder="ค้นหา"
                style={styles.inputSeach}
              />
              <View style={styles.viewSubject}>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "5%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  No.
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "30%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  E-mail
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "30%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  Phone
                </Text>
                {/* <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "10%",

                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  Status
                </Text> */}

                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Edit
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Delete
                </Text>
              </View>
              <FlatList
                data={state.filter((item) => {
                  return (
                    item.email.toLowerCase().includes(search.toLowerCase()) && item.delete_flag == false
                  );
                })}
                numColumns={1}
                renderItem={({ index, item }) => {
                  console.log("USER", item);
                  return (
                    <View style={styles.viewDetailAccess}>
                      <Text
                        style={[
                          styles.textDetail,
                          {
                            width: "5%",
                            textAlign: "left",
                            fontSize: (size.width * 1) / 100,
                          },
                        ]}
                      >
                        {index + 1}
                      </Text>
                      <Text
                        style={[
                          styles.textDetail,
                          {
                            width: "30%",
                            textAlign: "left",
                            fontSize: (size.width * 1) / 100,
                          },
                        ]}
                      >
                        {item.email}
                      </Text>
                      <Text
                        style={[
                          styles.textDetail,
                          {
                            width: "30%",
                            textAlign: "left",
                            fontSize: (size.width * 1) / 100,
                          },
                        ]}
                      >
                        {item.phone}
                      </Text>

                      {/* <Text
                        style={[
                          styles.textDetail,
                          {
                            width: "10%",
                            color: "#26BB2C",
                            fontSize: (size.width * 1) / 100,
                          },
                        ]}
                      >
                        {item.status}
                      </Text> */}

                      <View style={{ width: "10%" }}>
                        <TouchableOpacity
                          onPress={async () => {
                            const response = await apiservice({
                              method: "get",
                              path: "api/admin_users/" + item.user_uid,
                              token: token.access_token,
                            });
                            console.log("userID", response.data);
                            if (response.status == 200) {
                              setModalUserED(true);
                              setdata(response.data.result);
                            }
                          }}
                          style={{ alignSelf: "center" }}
                        >
                          <Image
                            style={{
                              width: (size.width * 1.5) / 100,
                              height: (size.width * 1.5) / 100,
                            }}
                            source={require("../../img/edit.png")}
                          />
                        </TouchableOpacity>
                      </View>
                      <View style={{ width: "10%" }}>
                        <TouchableOpacity
                          onPress={async () => {
                            const response = await apiservice({
                              method: "get",
                              path: "api/admin_users/" + item.user_uid,
                              token: token.access_token,
                            });
                            console.log("user", response.data);
                            setdata(response.data.result);
                            setModalDelete(true);
                          }}
                          style={{ alignSelf: "center" }}
                        >
                          <Image
                            style={{
                              width: (size.width * 1.5) / 100,
                              height: (size.width * 1.6) / 100,
                            }}
                            source={require("../../img/deleteIC.png")}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  );
                }}
              />
            </View>
          </View>
        </View>
      </View>
      <Modal
        transparent={true}
        visible={modalDelete}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelete(!modalDelete);
        }}
      >
        <View style={styles.bgModal}>
          <View style={styles.viewDeleteModal}>
            <Text
              style={{ fontSize: 24, fontFamily: "Roboto", color: "#FFFFFF" }}
            >
              Delete
            </Text>
          </View>
          <View
            style={[
              styles.viewModal,
              {
                width: "30%",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                alignItems: "center",
              },
            ]}
          >
            <Text
              style={{
                fontSize: (size.width * 1.2) / 100,
                fontFamily: "Roboto",
                color: "#404040",
              }}
            >
              Do you want to delete data?
            </Text>
            <View
              style={{
                flexDirection: "row",
                marginTop: 20,
                alignSelf: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalDelete(!modalDelete);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textSubjectModal,
                    { color: "#808080", fontSize: 16 },
                  ]}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  console.log("userID", data);
                  const response = await apiservice({
                    method: "Delete",
                    path: "api/admin_users/" + data.user_uid,
                    token: token.access_token,
                  });
                  console.log("user", response.data);
                  if (response.status == 200) {
                    userall();
                    setModalDelete(!modalDelete);
                  }
                }}
                style={[
                  styles.buttonModal,
                  { backgroundColor: "#161A31", marginLeft: 10 },
                ]}
              >
                <Text
                  style={[
                    styles.textSubjectModal,
                    { color: "#FFFFFF", fontSize: 16 },
                  ]}
                >
                  Ok
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalUser}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalUser(!modalUser);
        }}
      >
        <View style={styles.bgModal}>
          <View style={styles.viewModal}>
            <Text style={styles.textTitleModal}>User information</Text>
            <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>Email</Text>
              {data != null && (
                <TextInput
                  defaultValue={data.email}
                  style={styles.inputModal}
                />
              )}
            </View>
            <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>Phone</Text>
              <TextInput defaultValue={data.phone} style={styles.inputModal} />
            </View>
            {/* <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>Status</Text>
              <TextInput defaultValue={data.phone} style={styles.inputModal} />
            </View> */}
            <View
              style={{
                flexDirection: "row",
                marginTop: 20,
                alignSelf: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalUser(!modalUser);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textSubjectModal1,
                    { color: "#808080", fontSize: 16 },
                  ]}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setModalUser(!modalUser);
                }}
                style={[
                  styles.buttonModal,
                  { backgroundColor: "#161A31", marginLeft: 10 },
                ]}
              >
                <Text
                  style={[
                    styles.textSubjectModal1,
                    { color: "#FFFFFF", fontSize: 16 },
                  ]}
                >
                  Save
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        transparent={true}
        visible={modalUserED}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalUserED(!modalUserED);
        }}
      >
        <View style={styles.bgModal}>
          <View style={styles.viewModal}>
            <Text style={styles.textTitleModal}>User information</Text>
            <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>Email</Text>
              {data != null && (
                <TextInput
                  onChangeText={(text) => {
                    setemail(text);
                  }}
                  defaultValue={data.email}
                  style={styles.inputModal}
                />
              )}
            </View>
            {/* const [Email, setemail] = useState("");
  const [phone, setphone] = useState(""); */}
            <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>Phone</Text>
              <TextInput
                onChangeText={(text) => {
                  setphone(text);
                }}
                defaultValue={data.phone}
                style={styles.inputModal}
              />
            </View>
            {/* <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>Status</Text>
              <TextInput defaultValue={data.phone} style={styles.inputModal} />
            </View> */}
            <View
              style={{
                flexDirection: "row",
                marginTop: 20,
                alignSelf: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalUserED(!modalUserED);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textSubjectModal1,
                    { color: "#808080", fontSize: 16 },
                  ]}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  const response = await apiservice({
                    method: "Put",
                    path: "api/admin_users/" + data.user_uid,
                    token: token.access_token,
                    body: {
                      email: Email != undefined ? Email : data.email,
                      phone: phone != undefined ? phone : data.phone,
                      verified: true,
                      is_active: true,
                    },
                  });
                  console.log("USERADD", response.data);
                  if (response.status == 200) {
                    setModalUserED(!modalUserED);
                  }
                }}
                style={[
                  styles.buttonModal,
                  { backgroundColor: "#161A31", marginLeft: 10 },
                ]}
              >
                <Text
                  style={[
                    styles.textSubjectModal1,
                    { color: "#FFFFFF", fontSize: 16 },
                  ]}
                >
                  Save
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal></ScrollView>
    </View>
  );
}
