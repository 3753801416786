import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  ScrollView,
  Alert,
  StyleSheet,
} from "react-native";
import Menu from "../../component/menu";
import HeaderTitle from "../../component/headerTitle";
import { useWindowSize } from "../../component/responsive";
import Modal from "modal-react-native-web";
import styles from "./Style";
import { apiservice } from "../../apiservice/api";
import { tokenState } from "../../reducer/Atom";
import { useRecoilState } from "recoil";
import * as DocumentPicker from "expo-document-picker";
import "react-calendar/dist/Calendar.css";

export default function Manage({ navigation }) {
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAddType, setModalAddType] = useState(false);
  const [modalEDType, setModalEdType] = useState(false);

  const [showImage, setShowImage] = useState('');


  const [token, settoken] = useRecoilState(tokenState);
  const [state, setstate] = useState([]);
  const [search, setsearch] = useState("");
  const [ADD, setADD] = useState("");
  const [data, setdata] = useState("");
  const size = useWindowSize();
  const anotherFunc = (val) => {
    setADD("");
  };

  async function pickup() {
    let result = await DocumentPicker.getDocumentAsync({ type: "image/*" });
    console.log('====================================');
    console.log(result.uri);
    console.log('====================================');
    setShowImage(result.uri);
  }

  async function mediatypeall() {
    const response = await apiservice({
      method: "get",
      path: "api/admin_media_types",
      token: token.access_token,
    });
    console.log("media", response.data);
    if (response.status == 200) {
      setstate(response.data.result.media);
    }
  }

  useEffect(() => {
    mediatypeall();
  }, [token]);

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <ScrollView>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Menu manage navigation={navigation} />
          <View style={{ width: "80%" }}>
            <HeaderTitle title="Manage media type" navigation={navigation} />
            <View style={[styles.viewDetail, { minHeight: "80%" }]}>
              <View style={{ width: "100%" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Image
                    style={styles.imgGlass}
                    source={require("../../img/glass-icon.png")}
                  />
                  <TextInput
                    onChangeText={setsearch}
                    placeholder="ค้นหา"
                    style={styles.inputSeach}
                  />
                  <View style={{ width: "20%" }}>
                    <TouchableOpacity
                      onPress={() => {
                        setModalAddType(true);
                      }}
                      style={styles.buttonAdd}
                    >
                      <Image
                        style={styles.imgAdd}
                        source={require("../../img/addMedia.png")}
                      />
                      <Text
                        style={{
                          fontSize: (size.width * 2) / 100,
                          fontFamily: "Roboto",
                          color: "#FFFFFF",
                          zIndex: 99,
                        }}
                      >
                        Add Media
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={styles.viewSubject}>
                  <Text
                    style={[
                      styles.textSublject,
                      {
                        width: "5%",
                        textAlign: "left",
                        fontSize: (size.width * 1) / 100,
                      },
                    ]}
                  >
                    No.
                  </Text>
                  <Text
                    style={[
                      styles.textSublject,
                      {
                        width: "20%",
                        textAlign: "left",
                        fontSize: (size.width * 1) / 100,
                      },
                    ]}
                  >
                    Name Type
                  </Text>
                  <Text
                    style={[
                      styles.textSublject,
                      {
                        width: "20%",
                        textAlign: "left",
                        fontSize: (size.width * 1) / 100,
                      },
                    ]}
                  >
                    Created Date
                  </Text>
                  <Text
                    style={[
                      styles.textSublject,
                      {
                        width: "20%",
                        textAlign: "left",
                        fontSize: (size.width * 1) / 100,
                      },
                    ]}
                  >
                    Created by
                  </Text>
                  {/* <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Status
                </Text> */}
                  <Text
                    style={[
                      styles.textSublject,
                      { width: "10%", fontSize: (size.width * 1) / 100 },
                    ]}
                  >
                    Edit
                  </Text>
                  <Text
                    style={[
                      styles.textSublject,
                      { width: "10%", fontSize: (size.width * 1) / 100 },
                    ]}
                  >
                    Delete
                  </Text>
                </View>
                <FlatList
                  data={state
                    .filter((item) => {
                      return item.type_name
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    })
                    .sort((a, b) => b.type_id - a.type_id)}
                  numColumns={1}
                  renderItem={({ index, item }) => {
                    console.log("ASD", item);

                    return (
                      <View style={styles.viewDetailAccess}>
                        <Text
                          style={[
                            styles.textDetail,
                            {
                              width: "5%",
                              textAlign: "left",
                              fontSize: (size.width * 1) / 100,
                            },
                          ]}
                        >
                          {index + 1}
                        </Text>
                        <Text
                          style={[
                            styles.textDetail,
                            {
                              width: "20%",
                              textAlign: "left",
                              fontSize: (size.width * 1) / 100,
                            },
                          ]}
                        >
                          {item.type_name}
                        </Text>
                        <Text
                          style={[
                            styles.textDetail,
                            {
                              width: "20%",
                              textAlign: "left",
                              fontSize: (size.width * 1) / 100,
                            },
                          ]}
                        >
                          {item.created_at}
                        </Text>
                        <Text
                          style={[
                            styles.textDetail,
                            {
                              width: "20%",
                              textAlign: "left",
                              fontSize: (size.width * 1) / 100,
                            },
                          ]}
                        >
                          Admin-1234
                        </Text>
                        {/* <Text
                        style={[
                          styles.textDetail,
                          {
                            width: "10%",
                            color: "#26BB2C",
                            fontSize: (size.width * 1) / 100,
                          },
                        ]}
                      >
                        {item.status}
                      </Text> */}

                        <View style={{ width: "10%" }}>
                          <TouchableOpacity
                            onPress={async () => {
                              const response = await apiservice({
                                method: "get",
                                path: "api/admin_media_types/" + item.type_slug,
                                token: token.access_token,
                              });
                              console.log("types", response.data);
                              if (response.status == 200) {
                                setdata(response.data.result.media_type);
                                setADD(response.data.result.media_type.type_name)
                                setModalEdType(!modalEDType);
                              }
                            }}
                            style={{ alignSelf: "center" }}
                          >
                            <Image
                              style={{
                                width: (size.width * 1.5) / 100,
                                height: (size.width * 1.5) / 100,
                              }}
                              source={require("../../img/edit.png")}
                            />
                          </TouchableOpacity>
                        </View>
                        <View style={{ width: "10%" }}>
                          <TouchableOpacity
                            onPress={async () => {
                              const response = await apiservice({
                                method: "get",
                                path: "api/admin_media_types/" + item.type_slug,
                                token: token.access_token,
                              });
                              console.log("types", response.data);
                              if (response.status == 200) {
                                setdata(response.data.result.media_type);
                                setModalDelete(true);
                              }
                            }}
                            style={{ alignSelf: "center" }}
                          >
                            <Image
                              style={{
                                width: (size.width * 1.5) / 100,
                                height: (size.width * 1.6) / 100,
                              }}
                              source={require("../../img/deleteIC.png")}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    );
                  }}
                />
              </View>
            </View>
          </View>
        </View>

        <Modal
          transparent={true}
          visible={modalDelete}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalDelete(!modalDelete);
          }}
        >
          <View style={styles.bgModal}>
            <View style={styles.viewDeleteModal}>
              <Text
                style={{ fontSize: 24, fontFamily: "Roboto", color: "#FFFFFF" }}
              >
                Delete
              </Text>
            </View>
            <View
              style={[
                styles.viewModal,
                {
                  width: "30%",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={{
                  fontSize: (size.width * 1.2) / 100,
                  fontFamily: "Roboto",
                  color: "#404040",
                }}
              >
                Do you want to delete data?
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignSelf: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setModalDelete(!modalDelete);
                  }}
                  style={styles.buttonModal}
                >
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#808080", fontSize: 16 },
                    ]}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={async () => {
                    console.log("asdasd", data);
                    const response = await apiservice({
                      method: "Delete",
                      path: "api/admin_media_types/" + data.type_slug,
                      token: token.access_token,
                      body: {
                        type_name: data.type_name,
                      },
                    });
                    console.log("user", response.data);
                    if (response.status == 200) {
                      mediatypeall();
                      setModalDelete(!modalDelete);
                    }
                  }}
                  style={[
                    styles.buttonModal,
                    { backgroundColor: "#161A31", marginLeft: 10 },
                  ]}
                >
                  <Text
                    style={[
                      styles.textSubjectModal,
                      { color: "#FFFFFF", fontSize: 16 },
                    ]}
                  >
                    Ok
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>

        <Modal
          transparent={true}
          visible={modalAddType}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalAddType(!modalAddType);
          }}
        >
          <View style={styles.bgModal}>
            <View style={styles.viewModal}>
              <Text style={styles.textTitleModal}>Add Type</Text>
              <View
                style={{
                  marginTop: 17,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "78%" }}>
                  <Text style={styles.textSubjectModal1}>Name</Text>
                  <TextInput
                    value={ADD}
                    placeholder="Name"
                    onChangeText={(text) => {
                      setADD(text);
                    }}
                    style={styles.inputModal}
                  />

                  {showImage && <Image
                    style={{
                      width: 65,
                      height: 150,
                      borderRadius: 5,
                      marginTop: 15,
                    }}
                    source={{ uri: showImage }}
                  />}

                  <TouchableOpacity
                    onPress={pickup}
                    style={styles.buttonUploadImgModal}
                  >
                    <Image
                      style={{
                        width: 28,
                        height: 19,
                        borderRadius: 5,
                      }}
                      source={require("../../img/uploadicon.png")}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              <View
                style={{
                  marginTop: 17,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              ></View>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignSelf: "flex-end",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    anotherFunc(ADD);
                    setModalAddType(!modalAddType);
                  }}
                  style={styles.buttonModal}
                >
                  <Text
                    style={[
                      styles.textSubjectModal1,
                      { color: "#808080", fontSize: 16 },
                    ]}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={async () => {
                    const response = await apiservice({
                      method: "Post",
                      path: "api/admin_media_types",
                      token: token.access_token,
                      body: { type_name: ADD, image_base64: showImage },
                    });
                    if (response.status == 200) {
                      setADD("");
                      mediatypeall();
                      setModalAddType(!modalAddType);
                    }
                  }}
                  style={[
                    styles.buttonModal,
                    { backgroundColor: "#161A31", marginLeft: 10 },
                  ]}
                >
                  <Text
                    style={[
                      styles.textSubjectModal1,
                      { color: "#FFFFFF", fontSize: 16 },
                    ]}
                  >
                    Save
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>

        <Modal
          transparent={true}
          visible={modalEDType}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalEdType(!modalEDType);
          }}
        >
          <View style={styles.bgModal}>
            <View style={styles.viewModal}>
              <Text style={styles.textTitleModal}>Add Type</Text>
              <View
                style={{
                  marginTop: 17,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "78%" }}>
                  <Text style={styles.textSubjectModal1}>Name</Text>
                  <TextInput
                    value={ADD}
                    placeholder="Name"
                    onChangeText={(text) => {
                      setADD(text);
                    }}
                    style={styles.inputModal}
                  />

                  {showImage && <Image
                    style={{
                      width: 65,
                      height: 150,
                      borderRadius: 5,
                      marginTop: 15,
                    }}
                    source={{ uri: showImage }}
                  />}

                  {(data?.image_url && !showImage) && <Image
                    style={{
                      width: 65,
                      height: 150,
                      borderRadius: 5,
                      marginTop: 15,
                    }}
                    source={{ uri: data?.image_url }}
                  />}



                  <TouchableOpacity
                    onPress={pickup}
                    style={styles.buttonUploadImgModal}
                  >
                    <Image
                      style={{
                        width: 28,
                        height: 19,
                        borderRadius: 5,
                      }}
                      source={require("../../img/uploadicon.png")}
                    />
                  </TouchableOpacity>
                </View>
                {/* <View style={{ width: "20%" }}>
                <Text style={styles.textSubjectModal1}>No. of media</Text>
                <TextInput
                  placeholder="No. of media"
                  style={styles.inputModal}
                />
              </View> */}
              </View>
              <View
                style={{
                  marginTop: 17,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {/* <View style={{ width: "78%" }}>
                <Text style={styles.textSubjectModal1}>Created by</Text>
                <TextInput placeholder="Created by" style={styles.inputModal} />
              </View> */}
                {/* <View style={{ width: "20%" }}>
                <Text style={styles.textSubjectModal1}>Status</Text>
                <RNPickerSelect
                  placeholder={placeholder}
                  onValueChange={(val) => {
                    console.log("val>>>>", val);
                  }}
                  items={selectType}
                  style={{
                    inputWeb: {
                      backgroundColor: "#FFFFFF",
                      borderColor: "#dcdde0",
                      borderRadius: 5,
                      width: "100%",
                      height: 31,
                      color: "#8B8D98",
                      fontFamily: "Prompt",
                      fontSize: 16,
                      paddingHorizontal: 10,
                    },
                  }}
                />
              </View> */}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignSelf: "flex-end",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setADD("");
                    setModalEdType(!modalEDType);
                  }}
                  style={styles.buttonModal}
                >
                  <Text
                    style={[
                      styles.textSubjectModal1,
                      { color: "#808080", fontSize: 16 },
                    ]}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={async () => {
                    const response = await apiservice({
                      method: "Put",
                      path: "api/admin_media_types/" + data.type_slug,
                      token: token.access_token,
                      body: { type_name: ADD != undefined ? ADD : data.name, image_base64: showImage.length ? showImage : null },
                    });

                    if (response.status == 200) {
                      setADD("");
                      mediatypeall();
                      setModalEdType(!modalEDType);
                    }
                  }}
                  style={[
                    styles.buttonModal,
                    { backgroundColor: "#161A31", marginLeft: 10 },
                  ]}
                >
                  <Text
                    style={[
                      styles.textSubjectModal1,
                      { color: "#FFFFFF", fontSize: 16 },
                    ]}
                  >
                    Save
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </View>
  );
}

