import { StyleSheet } from "react-native";

export default StyleSheet.create({
  viewDetail: {
    width: "95%",
    marginRight: 25,
    alignSelf: "flex-end",
    backgroundColor: "#FFFFFF",
    marginTop: -50,
    borderRadius: 4,
    padding: 40,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  imgGlass: {
    width: 22,
    height: 22,
    position: "absolute",
    zIndex: 99,
    marginTop: 18,
    marginLeft: 10,
  },
  inputSeach: {
    width: "50%",
    height: 58,
    backgroundColor: "#E7E7E7",
    borderRadius: 5,
    paddingLeft: 40,
    paddingRight: 10,
    fontSize: 16,
    color: "#a2a2a2",
    fontFamily: "Roboto",
  },
  textSublject: {
    fontSize: 20,
    fontFamily: "Roboto",
    color: "#FFFFFF",
    textAlign: "center",
  },
  viewSubject: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#161A31",
    borderRadius: 5,
    height: 59,
    paddingHorizontal: 45,
    alignItems: "center",
    marginTop: 33,
  },
  textDetail: {
    fontSize: 20,
    fontFamily: "Roboto",
    color: "#535659",
    textAlign: "center",
  },
  viewDetailAccess: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#e8e9eb",
    paddingVertical: 22,
    paddingHorizontal: 45,
    marginTop: 17,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewModal: {
    width: "40%",
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    padding: 35,
    alignSelf: "center",
  },
  viewModal: {
    width: "40%",
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    padding: 35,
    alignSelf: "center",
  },
  textTitleModal: {
    fontSize: 36,
    fontFamily: "Roboto",
    color: "#161A31",
    alignSelf: "center",
    fontWeight: "bold",
  },
  buttonModal: {
    width: 87,
    height: 33,
    backgroundColor: "#E8E9EB",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  viewDeleteModal: {
    width: "30%",
    height: 44,
    backgroundColor: "#161A31",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  viewModal: {
    width: "40%",
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    padding: 35,
    alignSelf: "center",
  },
  buttonUploadImgModal: {
    width: 95,
    height: 40,
    borderRadius: 16,
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#161A31'
  },
  textTitleModal: {
    fontSize: 36,
    fontFamily: "Roboto",
    color: "#161A31",
    alignSelf: "center",
    fontWeight: "bold",
  },
  textSubjectModal1: {
    fontSize: 18,
    fontFamily: "Roboto",
    color: "#161A31",
    fontWeight: "bold",
  },
  inputModal: {
    width: "100%",
    height: 31,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#dcdde0",
    paddingHorizontal: 10,
  },
  buttonAdd: {
    maxWidth: 241,
    width: "100%",
    height: 58,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    alignSelf: "flex-end",
  },
  imgAdd: {
    position: "absolute",
    maxWidth: 241,
    width: "100%",
    height: 58,
    borderRadius: 5,
  },
});
