import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  FlatList,
  ScrollView,
} from "react-native";
import Menu from "../../component/menu";
import HeaderTitle from "../../component/headerTitle";
import { useWindowSize } from "../../component/responsive";
import Modal from "modal-react-native-web";
import styles from "./Style";
import { apiservice } from "../../apiservice/api";
import { tokenState } from "../../reducer/Atom";
import { useRecoilState } from "recoil";
import { useIsFocused } from "@react-navigation/native";

export default function User({ navigation }) {
  const [modalDelete, setModalDelete] = useState(false);
  const [modalUser, setModalUser] = useState(false);
  const [modalUserED, setModalUserED] = useState(false);
  const size = useWindowSize();
  const [state, setstate] = useState([]);
  const [data, setdata] = useState("");
  const [Email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [token, settoken] = useRecoilState(tokenState);
  const [search, setsearch] = useState("");
  const [name, setname] = useState("");
  const [modalerror, setModalEderrof] = useState(false);

  const [modalEdit, setmodalEdit] = useState(false);

  const [ED, setED] = useState({});
  const [Delete, setDelete] = useState({});

  async function userall() {
    const response = await apiservice({
      method: "get",
      path: "api/admin/alladmin",
      token: token.access_token,
    });
    console.log("admin", response.data);
    if (response.status == 200) {
      setstate(response.data);
    }
  }
  const focus = useIsFocused();
  useEffect(() => {
    userall();
  }, [token, focus]);

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Menu Admin navigation={navigation} />
        <View style={{ width: "80%" }}>
          <HeaderTitle title="Admin information" navigation={navigation} />
          <View style={[styles.viewDetail, { minHeight: "80%" }]}>
            <View style={{ width: "100%" }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Image
                  style={styles.imgGlass}
                  source={require("../../img/glass-icon.png")}
                />

                <TextInput
                  onChangeText={setsearch}
                  placeholder="ค้นหา"
                  style={styles.inputSeach}
                />
                <TouchableOpacity
                  onPress={() => {
                    setModalUserED(!modalUserED);
                  }}
                  style={styles.buttonAdd}
                >
                  <Image
                    style={styles.imgAdd}
                    source={require("../../img/addMedia.png")}
                  />
                  <Text
                    style={{
                      fontSize: (size.width * 2) / 100,
                      fontFamily: "Roboto",
                      color: "#FFFFFF",
                      zIndex: 99,
                    }}
                  >
                    Add admin
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.viewSubject}>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "5%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  No.
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "50%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  E-mail
                </Text>

                <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "30%",
                      textAlign: "left",
                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  Name
                </Text>
                {/* <Text
                  style={[
                    styles.textSublject,
                    {
                      width: "10%",

                      fontSize: (size.width * 1) / 100,
                    },
                  ]}
                >
                  Status
                </Text> */}

                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Edit
                </Text>
                <Text
                  style={[
                    styles.textSublject,
                    { width: "10%", fontSize: (size.width * 1) / 100 },
                  ]}
                >
                  Delete
                </Text>
              </View>
              <FlatList
                data={state.filter((item) => {
                  return (
                    item.email.toLowerCase().includes(search.toLowerCase()) &&
                    item.delete_flag == false
                  );
                })}
                numColumns={1}
                renderItem={({ index, item }) => {
                  console.log("USER", item);
                  return (
                    <View style={styles.viewDetailAccess}>
                      <Text
                        style={[
                          styles.textDetail,
                          {
                            width: "5%",
                            textAlign: "left",
                            fontSize: (size.width * 1) / 100,
                          },
                        ]}
                      >
                        {index + 1}
                      </Text>
                      <Text
                        style={[
                          styles.textDetail,
                          {
                            width: "50%",
                            textAlign: "left",
                            fontSize: (size.width * 1) / 100,
                          },
                        ]}
                      >
                        {item.email}
                      </Text>

                      <Text
                        style={[
                          styles.textDetail,
                          {
                            width: "30%",
                            textAlign: "left",
                            fontSize: (size.width * 1) / 100,
                          },
                        ]}
                      >
                        {item.admin_alias}
                      </Text>

                      <View style={{ width: "10%" }}>
                        <TouchableOpacity
                          onPress={async () => {
                            setED(item);
                            setmodalEdit(!modalEdit);
                          }}
                          style={{ alignSelf: "center" }}
                        >
                          <Image
                            style={{
                              width: (size.width * 1.5) / 100,
                              height: (size.width * 1.5) / 100,
                            }}
                            source={require("../../img/edit.png")}
                          />
                        </TouchableOpacity>
                      </View>

                      <View style={{ width: "10%" }}>
                        <TouchableOpacity
                          onPress={async () => {
                            if (state.length >= 1) {
                              setDelete(item);
                              setModalDelete(true);
                              console.log("NO");
                            } else {
                              console.log("Me");
                            }
                          }}
                          style={{ alignSelf: "center" }}
                        >
                          <Image
                            style={{
                              width: (size.width * 1.5) / 100,
                              height: (size.width * 1.6) / 100,
                            }}
                            source={require("../../img/deleteIC.png")}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  );
                }}
              />
            </View>
          </View>
        </View>
      </View>
      <Modal
        transparent={true}
        visible={modalDelete}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelete(!modalDelete);
        }}
      >
        <View style={styles.bgModal}>
          <View style={styles.viewDeleteModal}>
            <Text
              style={{ fontSize: 24, fontFamily: "Roboto", color: "#FFFFFF" }}
            >
              Delete
            </Text>
          </View>
          <View
            style={[
              styles.viewModal,
              {
                width: "30%",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                alignItems: "center",
              },
            ]}
          >
            <Text
              style={{
                fontSize: (size.width * 1.2) / 100,
                fontFamily: "Roboto",
                color: "#404040",
              }}
            >
              Do you want to delete data?
            </Text>
            <View
              style={{
                flexDirection: "row",
                marginTop: 20,
                alignSelf: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalDelete(!modalDelete);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textSubjectModal,
                    { color: "#808080", fontSize: 16 },
                  ]}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  const response = await apiservice({
                    method: "Put",
                    path: "api/admin/update",
                    body: {
                      ...Delete,
                      delete_flag: true,
                    },
                    token: token.access_token,
                  });
                  console.log("user", response.data);
                  if (response.status == 200) {
                    userall();
                    setModalDelete(!modalDelete);
                  }
                }}
                style={[
                  styles.buttonModal,
                  { backgroundColor: "#161A31", marginLeft: 10 },
                ]}
              >
                <Text
                  style={[
                    styles.textSubjectModal,
                    { color: "#FFFFFF", fontSize: 16 },
                  ]}
                >
                  Ok
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        transparent={true}
        visible={modalUserED}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalUserED(!modalUserED);
        }}
      >
        <View style={styles.bgModal}>
          <View style={styles.viewModal}>
            <Text style={styles.textTitleModal}>User information</Text>
            <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>Name</Text>

              <TextInput
                onChangeText={(text) => {
                  setname(text);
                }}
                style={styles.inputModal}
              />
            </View>
            <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>Email</Text>

              <TextInput
                onChangeText={(text) => {
                  setemail(text);
                }}
                style={styles.inputModal}
              />
            </View>

            <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>password</Text>
              <TextInput
                onChangeText={(text) => {
                  setpassword(text);
                }}
                style={styles.inputModal}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                marginTop: 20,
                alignSelf: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setname("");
                  setpassword("");
                  setemail("");
                  setModalUserED(!modalUserED);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textSubjectModal1,
                    { color: "#808080", fontSize: 16 },
                  ]}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  const response = await apiservice({
                    method: "Post",
                    path: "api/admin/register",
                    token: token.access_token,
                    body: {
                      alias: name,
                      email: Email,
                      password: password,
                    },
                  });
                  console.log("ADMIN", response.data);
                  if (response.status == 200) {
                    setname("");
                    setpassword("");
                    setemail("");
                    setModalUserED(!modalUserED);
                    userall();
                  } else {
                    setModalEderrof(!modalerror);
                  }
                }}
                style={[
                  styles.buttonModal,
                  { backgroundColor: "#161A31", marginLeft: 10 },
                ]}
              >
                <Text
                  style={[
                    styles.textSubjectModal1,
                    { color: "#FFFFFF", fontSize: 16 },
                  ]}
                >
                  Save
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        transparent={true}
        visible={modalEdit}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setmodalEdit(!modalEdit);
        }}
      >
        <View style={styles.bgModal}>
          <View style={styles.viewModal}>
            <Text style={styles.textTitleModal}>User information</Text>
            <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>Name</Text>

              <TextInput
                defaultValue={ED.admin_alias}
                onChangeText={(text) => {
                  setED({ ...ED, admin_alias: text });
                }}
                style={styles.inputModal}
              />
            </View>
            <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>Email</Text>

              <TextInput
                defaultValue={ED.email}
                onChangeText={(text) => {
                  setED({ ...ED, email: text });
                }}
                style={styles.inputModal}
              />
            </View>

            <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>Password</Text>

              <TextInput
                onChangeText={(text) => {
                  setED({ ...ED, password: text });
                }}
                style={styles.inputModal}
              />
            </View>

            {/* <View style={{ marginTop: 17 }}>
              <Text style={styles.textSubjectModal1}>password</Text>
              <TextInput
                onChangeText={(text) => {
                  setpassword(text);
                }}
                style={styles.inputModal}
              />
            </View> */}

            <View
              style={{
                flexDirection: "row",
                marginTop: 20,
                alignSelf: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setname("");
                  setpassword("");
                  setemail("");
                  setmodalEdit(!modalEdit);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textSubjectModal1,
                    { color: "#808080", fontSize: 16 },
                  ]}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  const response = await apiservice({
                    method: "Put",
                    path: "api/admin/update",
                    token: token.access_token,
                    body: {
                      ...ED,
                    },
                  });
                  console.log("ADMIN", response.data);
                  if (response.status == 200) {
                    setname("");
                    setpassword("");
                    setemail("");
                    setmodalEdit(!modalEdit);
                    userall();
                  } else {
                    setmodalEdit(!modalEdit);
                  }
                }}
                style={[
                  styles.buttonModal,
                  { backgroundColor: "#161A31", marginLeft: 10 },
                ]}
              >
                <Text
                  style={[
                    styles.textSubjectModal1,
                    { color: "#FFFFFF", fontSize: 16 },
                  ]}
                >
                  Save
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        transparent={true}
        visible={modalerror}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalEderrof(!modalerror);
        }}
      >
        <View style={styles.bgModal}>
          <View style={styles.viewDeleteModal}>
            <Text
              style={{ fontSize: 24, fontFamily: "Roboto", color: "#FFFFFF" }}
            >
              Error
            </Text>
          </View>
          <View
            style={[
              styles.viewModal,
              {
                width: "30%",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                alignItems: "center",
              },
            ]}
          >
            <Text
              style={{
                fontSize: (size.width * 1.2) / 100,
                fontFamily: "Roboto",
                color: "#404040",
              }}
            >
              รหัสผ่านไม่ถูกต้อง รหัสผ่านต้องมีความยาวอย่างน้อย 8 อักขระ
              ความยาวสูงสุดคือ 32 อักขระ และประกอบด้วยตัวเลขอย่างน้อยหนึ่งตัว
              ตัวพิมพ์ใหญ่หนึ่งตัว อักษรตัวพิมพ์เล็กหนึ่งตัว
              และอักขระพิเศษหนึ่งตัว
            </Text>
            <View
              style={{
                flexDirection: "row",
                marginTop: 20,
                alignSelf: "center",
              }}
            >
              <TouchableOpacity
                onPress={async () => {
                  setModalEderrof(!modalerror);
                }}
                style={[
                  styles.buttonModal,
                  { backgroundColor: "#161A31", marginLeft: 10 },
                ]}
              >
                <Text
                  style={[
                    styles.textSubjectModal,
                    { color: "#FFFFFF", fontSize: 16 },
                  ]}
                >
                  Ok
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
