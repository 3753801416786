import React, { useState, useRef, useEffect } from "react";
import { StyleSheet, Text, View } from "react-native";
import Route from "./src/route/index";
// import { useFonts } from "expo-font";
import { RecoilRoot } from "recoil";
import * as Font from "expo-font";
const App = () => {
  const [fontsLoaded, setFont] = useState(false);

  useEffect(() => {
    loadFonts();
  }, []);

  async function loadFonts() {
    await Font.loadAsync({
      Roboto: require("./assets/font/Prompt-Regular.ttf"),
    });

    setFont(true);
  }

  if (!fontsLoaded) {
    return null;
  } else {
    return (
      <RecoilRoot>
        <View style={styles.container}>
          <Route />
        </View>
      </RecoilRoot>
    );
  }
};
export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
