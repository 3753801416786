import * as React from "react";
import { Text } from "react-native";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import Home from "../page/Home/Home";
import Login from "../page/Login/login";
import Book from "../page/MediaInformation/Media";
import Detail from "../page/DetailMediaInformation/Detail";
import User from "../page/userInformation/User";
import Manage from "../page/ManageMediaType/Manage";
import Admin from "../page/Admin/Admin"

function Route() {
  const Stack = createStackNavigator();

  function screen() {
    return (
      <Stack.Navigator headerMode="none" initialRouteName={"Login"}>
        <Stack.Screen name="Login" component={Login} />
        <Stack.Screen name="Home" component={Home} />
        <Stack.Screen name="Book" component={Book} />
        <Stack.Screen name="Detail" component={Detail} />
        <Stack.Screen name="User" component={User} />
        <Stack.Screen name="Manage" component={Manage} />
        <Stack.Screen name="Admin" component={Admin} />
      </Stack.Navigator>
    );
  }
  const config = {
    screens: {
      Login: "/Login",
      Home: "/Home",
      Book: "/Mediainformation",
      Detail: "/Mediainformation/Detail",
      User: "/UserInformation",
      Manage: "/ManageMediaType",
      Admin:"/Admin"
    },
  };
  const linking = {
    // prefixes: [
    //   "https://www.asianathleticsassociation.com/",
    //   "asianAthleticsAssociation://",
    // ],
    config,
  };

  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      {screen()}
    </NavigationContainer>
  );
}

export default Route;
